import docIcon from '../../assets/docIcon.png'
import txtIcon from '../../assets/txtIcon.png'
import pdfIcon from '../../assets/pdfIcon.png'
import { useState, useEffect, useRef, createRef } from 'react';
import ApiService from '../../services/ApiService';
import axios from 'axios'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {ToastContainer, toast} from 'react-toastify'
import { useParams, useNavigate } from 'react-router-dom';
import {ThreeDots, Dna} from 'react-loader-spinner'
import TextareaAutosize from 'react-textarea-autosize';
import Pusher from 'pusher-js'


function Chat({dropFiles}) {
  let { id } = useParams();
  const nav = useNavigate();
  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const [files, setFiles] = useState(null);
  const [chats, setChats] = useState([])
  const [messages, setMessages] = useState([]);
  const [inp, setInp] = useState('')
  const [awaitingMessageResponse, setAwaitingMessageResponse] = useState(false)
  const [gettingInitData, setGettingInitData] = useState(false)
  const inputFiles = useRef(null) 
  const [context, setContext] = useState(true)
  const [pusherSet, setPusherSet] = useState(false)
  const [model, setModel] = useState('gpt-3.5-turbo')

  const handleFilesChange = (event) => {
    console.log(event.target.files)
    setFiles([...event.target.files]);
  };


const [pusherChannel, setPusherChannel] = useState(null);
const [data, setData] = useState(null);

// TRIGGERED ON MOUNT
useEffect(() => {
  if (id) {
    const pusher = new Pusher("103508ce95bd6166447d", {
      cluster: "eu"
    });
    const channel = pusher.subscribe(`${id}-chat`);
    setPusherChannel(channel);
  }

  // PREVIOUSLY
  // channel.bind(EVENT_NAME, (pusherData) => {
  //   ...
  //   Accessing "data" here would give the state used
  //   during binding the event
  //});
}, [id]);
  useEffect(() => {
  }, [messages]);

  useEffect(() => {
    if (dropFiles) {
      setFiles([...dropFiles])
    }
    if (id) {
      setGettingInitData(true)
      const getChat = async () => {
        const chat = await ApiService.get(`/chat/${id}`)
        setChats([chat.data])
      }
      const getMessages = async () => {
        const messages = await ApiService.get(`/messages/${id}`);
        setGettingInitData(false)
        setMessages(messages.data)
        
      }
      getMessages()
      getChat()
    } else {
      const newChat = async () => {
        setMessages([])
        setGettingInitData(true)
        const chat = await ApiService.post(`/chat`)
        setChats([chat.data])
        setGettingInitData(false)
        console.log(chat)
        nav(`/${chat.data._id}`)
      }
      newChat()
    }
  }, [id])


  
  useEffect(() => {
    console.log("Updated data : ", messages);
    if(pusherChannel && pusherChannel.bind)  {
      console.log("Unbinding Event");
      pusherChannel.unbind('message-chunk');
      pusherChannel.unbind('file-process-success');
      pusherChannel.unbind('file-process-fail');
      pusherChannel.unbind('file-processing');


      console.log("Rebinding Event");

      pusherChannel.bind('file-processing', (pusherData) => {
        console.log('file-processing')
        console.log(pusherData)
        const updateFileProcessingStatus = (fileId) => {
          setChats(prevChats => {
            const updatedFiles = prevChats[0].files.map(file => {
              if (file._id === fileId) {
                return {...file, processing: true, uploaded: true};
              }
              return file;
            });
            return [{...prevChats[0], files: updatedFiles}, ...prevChats.slice(1)];
          });
        }
        updateFileProcessingStatus(pusherData.file._id);

      })

      pusherChannel.bind('file-process-success', (pusherData) => {
        console.log('file-process-success')
        const updateFileProcessingStatus = (fileId) => {
          setChats(prevChats => {
            const updatedFiles = prevChats[0].files.map(file => {
              if (file._id === fileId) {
                return {...file, processing: false, uploaded: true};
              }
              return file;
            });
            return [{...prevChats[0], files: updatedFiles}, ...prevChats.slice(1)];
          });
        }
        updateFileProcessingStatus(pusherData.file._id);
      })

      pusherChannel.bind('file-process-fail', (pusherData) => {
        console.log('file-process-fail')
      })
      pusherChannel.bind('message-chunk', (pusherData) => {
        if (pusherData.partial) {
          const existingMessageIndex = messages.findIndex(m => m.id === pusherData.id);
          if (existingMessageIndex >= 0) {
            // If the message already exists, add the new partial to it.
            messages[existingMessageIndex].partials.push({
              content: pusherData.partial,
              order: pusherData.order
            });
            // Sort the partials based on their order.
            messages[existingMessageIndex].partials.sort((a, b) => a.order - b.order);
            // Concatenate the sorted partials to form the message content.
            messages[existingMessageIndex].content = messages[existingMessageIndex].partials
                                                      .map(p => p.content)
                                                      .join('');
          } else {
            // If the message doesn't exist yet, create a new one.
            messages.push({
              id: pusherData.id,
              role: 'assistant',
              content: pusherData.partial,
              partials: [{
                content: pusherData.partial,
                order: pusherData.order
              }]
            });
          }
          setMessages([...messages]);
        }
      });
      
    }
  }, [pusherChannel, messages,id]);
  

  const sendMessage = async (message) => {
    if (message === '') return;
    setAwaitingMessageResponse(true)
    setMessages([...messages, {role: 'user', content: message}])
    console.log(chats[0])
  
    try {
      const x = await ApiService.post(`/message`, {
        chatId: chats[0] ? chats[0]._id : null,
        content: message,
        context,
        model
      })  
    scrollToBottom()

      console.log(chats[0])
      console.log(x.data.chat)
      if (!chats[0]) {
        console.log("setting chats")
        nav(`/${x.data.chat._id}`)
        setChats([x.data.chat, ...chats])
      }
    } catch(e) {
      console.error(e)

    }

    setAwaitingMessageResponse(false)

  }

  const handleMessageBoxChange = (event) => {
    setInp(event.target.value)
  }

  const changeModel = () => {
    console.log(model)
    if (model === 'gpt-3.5-turbo') {
      setModel('gpt-4')
    } else {
      setModel('gpt-3.5-turbo')
    }
    //setModel(model === 'gpt-3.5-turbo' ? 'gpt-4' : 'gpt-3-5-turbo')
  }


  const getIcon = (file) => {
    if (file.contentType === 'application/pdf') {
      return pdfIcon
    } 
    if (file.contentType === 'text/plain') {
      return txtIcon
    }
    if (file.contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      return docIcon
    }
  }

  useEffect(() => {
    if (!files) return;
    if (files.length === 0) return;
    console.log(files)
        const uploadFiles = async (files,t) => {
          console.log("Uploading files to s3")
          console.log(files)
          for (let file of files) {
            console.log(file)
            try {
              const result = await axios.put(file.url, file.file, {
                headers: {
                  'Content-Type': file.file.type,
                },
              });

              console.log(result)
            } catch(e) {
              console.log(e)
              toast.update(t,{render:"An error occured uploading files", autoClose:1000, type:'error', isLoading:false})
            }
         
          } 
          console.log("done")
          toast.update(t,{render:"Files uploaded", autoClose:1000, type:'success', isLoading:false})
          
        }

        const addFilesToChat = async (files) => {
          console.log("Adding files to chat")
          const t = toast.loading('Uploading files', {
            position: "top-center",
            hideProgressBar: false,
            pauseOnHover: true,
            progress: false,
            theme: "light",
            });
            const fileNames = files.map(file => {return {name: file.name, contentType: file.type}})
            const chat = await ApiService.post(`/chat/${chats[0]._id}/files`, {
              files: fileNames,
            })
            console.log(chat.data)
            let x = files.map((file,i) => { return {file, url: chat.data.urls[i]}})
            console.log(x)
           setChats([chat.data])
            await uploadFiles(x,t)

        }
        const startNewChatWithFiles = async (files) => {
          const t = toast.loading('Starting chat', {
            position: "top-center",
            hideProgressBar: false,
            pauseOnHover: true,
            progress: false,
            theme: "light",
            });
          const fileNames = files.map(file => {return {name: file.name, contentType: file.type}})
           console.log(chats)
            const chat = await ApiService.post(`/chat`, {
              id: chats[0] ? chats[0]._id : null,
              files: fileNames,
            })
            nav(`/${chat.data._id}`)
            toast.update(t,{render:'Uploading files'})
            console.log(chat)
        
            setChats([chat.data])
            let x = files.map((file,i) => { return {file, url: chat.data.urls[i]}})
            console.log(x)
            await uploadFiles(x,t)
      
        }
      console.log(files)  
      if (chats.length === 0) {
        startNewChatWithFiles(files)
      } else {
        addFilesToChat(files)
      }
  }, [files])


 



    return (
      <>
      <div style={{width:'100vw', display:'flex'}}>
  
        <div onClick={() => {
          if (chats.length === 0){
            inputFiles.current.click()
          }
        }} style={{width:'260px', cursor: chats.length === 0 ?'pointer': 'default',minWidth:'260px', height:'100vh',background:'rgba(32,33,35)', display:'flex', flexDirection:'column'}}>
        <input accept=".txt,.pdf,.docx" ref={inputFiles} style={{display:'none'}} type="file" multiple onChange={handleFilesChange} />
        {/* {(!chats[0] || chats[0].files.length <=0) && (
          <div style={{color: 'rgb(142,142,160)',textAlign:'center', marginTop:'50px', padding:'10px'}}>
          Click here to get started, you can interact without context while you wait.
          </div>
        )} */}
          <div onClick={() => nav('/', {replace:true})} style={{border: '1px solid hsla(0,0%,100%,.2)', color:'white', borderRadius: '.375rem', padding:'16px', margin:'8px 8px 2px 8px', cursor: 'pointer'}}>
                <p >
                <svg style={{height:'16px', fill:'white',marginRight:'10px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M88.2 309.1c9.8-18.3 6.8-40.8-7.5-55.8C59.4 230.9 48 204 48 176c0-63.5 63.8-128 160-128s160 64.5 160 128s-63.8 128-160 128c-13.1 0-25.8-1.3-37.8-3.6c-10.4-2-21.2-.6-30.7 4.2c-4.1 2.1-8.3 4.1-12.6 6c-16 7.2-32.9 13.5-49.9 18c2.8-4.6 5.4-9.1 7.9-13.6c1.1-1.9 2.2-3.9 3.2-5.9zM0 176c0 41.8 17.2 80.1 45.9 110.3c-.9 1.7-1.9 3.5-2.8 5.1c-10.3 18.4-22.3 36.5-36.6 52.1c-6.6 7-8.3 17.2-4.6 25.9C5.8 378.3 14.4 384 24 384c43 0 86.5-13.3 122.7-29.7c4.8-2.2 9.6-4.5 14.2-6.8c15.1 3 30.9 4.5 47.1 4.5c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176zM432 480c16.2 0 31.9-1.6 47.1-4.5c4.6 2.3 9.4 4.6 14.2 6.8C529.5 498.7 573 512 616 512c9.6 0 18.2-5.7 22-14.5c3.8-8.8 2-19-4.6-25.9c-14.2-15.6-26.2-33.7-36.6-52.1c-.9-1.7-1.9-3.4-2.8-5.1C622.8 384.1 640 345.8 640 304c0-94.4-87.9-171.5-198.2-175.8c4.1 15.2 6.2 31.2 6.2 47.8l0 .6c87.2 6.7 144 67.5 144 127.4c0 28-11.4 54.9-32.7 77.2c-14.3 15-17.3 37.6-7.5 55.8c1.1 2 2.2 4 3.2 5.9c2.5 4.5 5.2 9 7.9 13.6c-17-4.5-33.9-10.7-49.9-18c-4.3-1.9-8.5-3.9-12.6-6c-9.5-4.8-20.3-6.2-30.7-4.2c-12.1 2.4-24.7 3.6-37.8 3.6c-61.7 0-110-26.5-136.8-62.3c-16 5.4-32.8 9.4-50 11.8C279 439.8 350 480 432 480z"/></svg>
                New chat
              </p>
          
              </div>  
        {chats[0] && (
          <div onClick={() => inputFiles.current.click()} style={{border: '1px solid hsla(0,0%,100%,.2)', color:'white', borderRadius: '.375rem', padding:'16px', margin:'2px 8px', cursor: 'pointer'}}>
                <p >
                ➕ Add Files
              </p>
          
              </div>  
          )}  
        {chats.map((thread, index) => (
              <div key={index} style={{color:'white', height:'100%', overflow:'scroll', borderRadius: '.375rem',backgroundColor:'rgba(52,53,65,1)', padding:'20px', margin:'8px',}}>
                <p style={{marginBottom:'12px'}}>
                <strong>{thread.name ? thread.name : 'New chat'}</strong>

              </p>
              {thread.files.length <= 0 && (
                <p>No files yet</p>
              )}
              {thread.files.length > 0 && thread.files.map((file, index) => (<div key={index} style={{display:'flex', marginBottom:'6px'}}>
                <img src={getIcon(file)} alt="PDF logo" style={{ width: '1rem', height:'1rem' }} />
                <p style={{fontSize:'10px', width:'150px'}}>
                {file.name}
                </p>
                <div style={{
                  height: '12px',
                  width: '12px',
                  marginLeft: 'auto',
                  marginTop:  'auto',
                  marginBottom: 'auto'
                }}>
            
                     {(file.ready) && (
                <svg style={{fill: 'green'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                )}

                {file.processing && (
                            <Dna 
                            height="20" 
                            width="20" 
                            radius="9"
                            color="gray" 
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                            />
                )}
           
            
                {(file.processFailed && !file.processing) && (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 512"><path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V320c0 17.7 14.3 32 32 32s32-14.3 32-32V64zM32 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>                )}
                  </div>
                </div>))}
              </div>        
          ))}
          <div>
          
          </div>
        <div style={{display:'flex', color:'white', alignSelf:'flex-end', width:'100%',marginTop:'auto', flexDirection:'column'}}>
        <div style={{ border: '1px solid hsla(0,0%,100%,.2)', color:'white', borderRadius: '.375rem', padding:'16px', margin:'2px 8px', cursor: 'pointer', opacity:'0.4'}}>
                <p style={{display:'flex'}}>
                <svg style={{height:'16px', fill:'white',marginRight:'10px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 528 512"><path d="M264.4 116.3l-132 84.3 132 84.3-132 84.3L0 284.1l132.3-84.3L0 116.3 132.3 32l132.1 84.3zM131.6 395.7l132-84.3 132 84.3-132 84.3-132-84.3zm132.8-111.6l132-84.3-132-83.6L395.7 32 528 116.3l-132.3 84.3L528 284.8l-132.3 84.3-131.3-85z"/></svg>
                Link Dropbox
              </p>
          
              </div>  
              <div  style={{border: '1px solid hsla(0,0%,100%,.2)', color:'white', borderRadius: '.375rem', padding:'16px', margin:'2px 8px', cursor: 'pointer', opacity:'0.4'}}>
                <p style={{display:'flex'}}>
                <svg style={{height:'16px', fill:'white', marginRight:'10px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 32h214.6v214.6H0V32zm233.4 0H448v214.6H233.4V32zM0 265.4h214.6V480H0V265.4zm233.4 0H448V480H233.4V265.4z"/></svg>
                Link Onedrive
              </p>
          
              </div>  
              <div  style={{border: '1px solid hsla(0,0%,100%,.2)', color:'white', borderRadius: '.375rem', padding:'16px', margin:'2px 8px', cursor: 'pointer', opacity:'0.4'}}>
                <p style={{display:'flex'}}>
                <svg style={{height:'16px', fill:'white', marginRight:'10px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M339 314.9L175.4 32h161.2l163.6 282.9H339zm-137.5 23.6L120.9 480h310.5L512 338.5H201.5zM154.1 67.4L0 338.5 80.6 480 237 208.8 154.1 67.4z"/></svg>
                Link Google Drive
              </p>
          
              </div>  
              <div  style={{border: '1px solid hsla(0,0%,100%,.2)', color:'white', borderRadius: '.375rem', padding:'16px', margin:'2px 8px 4px 8px', cursor: 'pointer', opacity:'0.4'}}>
                <p style={{display:'flex'}}>
                <svg style={{height:'16px', fill:'white', marginRight:'10px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M217.9 105.9L340.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L217.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1L32 320c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM352 416l64 0c17.7 0 32-14.3 32-32l0-256c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64 0c53 0 96 43 96 96l0 256c0 53-43 96-96 96l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32z"/></svg>
                  Log in
              </p>
          
              </div>  
          </div>
      </div>
   
      <div style={{width:'100%', height:'100vh', backgroundColor:'white', display:'flex', flexDirection:'column'}}>
          <div onClick={() => changeModel()} style={{cursor:'pointer',marginLeft:'5px',marginTop:'5px',borderRadius:'5px',display:'flex',color:'white',position:'absolute', width:'auto', height:'25px', backgroundColor: model === 'gpt-3.5-turbo' ? 'rgb(25,195,125)' : 'rgb(171,104,255)'}}>
            <div style={{display:'flex', alignItems:'center', marginLeft:'5px'}}>
              <svg style={{fill:'white',height:'10px'}}xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 150.6c12.5-12.5 12.5-32.8 0-45.3l-96-96c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.7 96 32 96C14.3 96 0 110.3 0 128s14.3 32 32 32l306.7 0-41.4 41.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l96-96zm-333.3 352c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 416 416 416c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0 41.4-41.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-96 96c-12.5 12.5-12.5 32.8 0 45.3l96 96z"/></svg>
              <div style={{marginLeft:'5px', marginRight:'5px'}}>
               {model === 'gpt-3.5-turbo' ? 'GPT-3.5-turbo' : 'GPT-4'}
              </div>
            </div>
          </div>
        <div style={{height:'100%', overflowY:'scroll', 'msOverflowStyle': 'none' }}>
          {messages.length === 0 && (
          <div style={{height:'100%', display:'flex',alignItems:'center',justifyContent:'center', flexDirection:'column'}}>
          <h1 className="text-gradient">ContextAI</h1>
          {gettingInitData && (
            <ThreeDots 
            height="20" 
            width="20" 
            radius="9"
            color="gray" 
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
            />
          )}
          
        </div>
            )}
        
          {messages.map((message, index) => (
            <div key={index} style={{backgroundColor: message.role === 'assistant' ? 'rgba(247,247,248)':'white', paddingLeft:'40px', borderBottom:'1px solid rgb(217,217,217)'}}>
            <div style={{display:'flex', paddingBottom:'1.5rem', paddingTop:'1.5rem'}}>
              <div style={{minWidth:'15%' }}>
                {message.role === 'assistant' && ("contextAI")}
                {message.role === 'user' && ("me")}
              </div>
              <div style={{whiteSpace: 'pre-wrap', color: '#374151', paddingRight:'30px'}}>
                {/* <ReactMarkdown remarkPlugins={[remarkGfm]}> */}
                {message.content}
                {/* </ReactMarkdown> */}
              </div>
            </div>
            {(index === messages.length - 1) && (
                <div ref={messagesEndRef} />
              )}
              </div>

         
          
          ))}

        </div>
    
        
        <div style={{width:'768px', alignSelf:'center'}}>
          <div style={{paddingLeft: '1rem', paddingTop:'0.75rem', paddingBottom: '0.75rem'}}>
        <div style={{boxShadow: '0 0 2px rgb(0 0 0 / 0%), 0 1px 2px rgb(0 0 0 / 0%), 0 0 10px rgb(176 47 203 / 10%)',border:'1px solid rgb(47,47,47,0.18)', width:'100%', maxHeight:'200px', fontSize:'18px', paddingBottom:'0.75rem', paddingTop:'0.75rem',paddingLeft:'1rem', paddingRight:'1em', borderRadius:'.375rem', alignItems:'center', display:'flex'}}>
        {!context && (
        <svg onClick={() => setContext(!context)} style={{height:'16px',marginRight:'10px', cursor:'pointer'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M320 464c8.8 0 16-7.2 16-16V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320zM0 64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64z"/></svg>
        )}

        {context && (
          <svg onClick={() => setContext(!context)} style={{height:'16px',marginRight:'10px', cursor:'pointer'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z"/></svg>
          )}
        <TextareaAutosize minRows={1} maxRows={3} onChange={handleMessageBoxChange} onKeyDown={async (e) => {
          if (e.target.value.length <=0 && e.key === 'Enter') {
            e.preventDefault()
          }
            if (e.key === 'Enter' && e.shiftKey) {
              e.target.value = e.target.value + '\n'
              setInp(e.target.value)
              return
            }
            if (e.key === 'Enter' && e.target.value.length > 0) {
              e.preventDefault()
              const newMess = e.target.value
              e.target.value = ''
              setInp("")
              await sendMessage(newMess)
            }
        }} style={{padding:0,resize:'none', width:'100%', paddingRight:'1.75rem', outline: 'none', fontSize:'1rem'}}/>
       {!awaitingMessageResponse && (
           <button onClick={async() => {
            if (inp.length > 0) {
            await sendMessage(inp)
            }
      }} style={{  display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '5px',
              backgroundImage: 'linear-gradient(45deg,#b126c0 25%,#5cc862)',
              opacity: inp.length > 0 ? '1': '0.6',
              border: 'none',
              borderRadius: '5px',
              color: 'white',
              cursor: 'pointer' }}>
      <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="h-4 w-4 mr-1" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
  
      </button>
       )}
       
        {awaitingMessageResponse && (
          <ThreeDots 
        height="20" 
        width="20" 
        radius="9"
        color="gray" 
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
        />
    )}
        </div>
        </div>
        </div>

      </div>
      
    </div>
  </> 
   
  );
}

export default Chat;
