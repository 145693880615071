import "./App.css"
import Land from "./pages/Land";  
import Chat from "./pages/Chat";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import {useState} from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [dropFiles, setDropFiles] =useState(null)

  return (
    <>
    <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route index element={<Chat dropFiles={dropFiles}/>}/>
        <Route path="/:id" element={<Chat dropFiles={dropFiles}/>}/>
        <Route path="*" element={<h1>404</h1>} />
      </Routes>
  </> 
   
  );
}

export default App;