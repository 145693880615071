import axios from 'axios';

class ApiService {
  constructor() {
    this.client = axios.create({
      baseURL: process.env.REACT_APP_API_URL, 
    });
  }

  get(endpoint, params = {}) {
    return this.client.get(endpoint, { params });
  }

  post(endpoint, data = {}) {
    return this.client.post(endpoint, data);
  }

  put(endpoint, data = {}) {
    return this.client.put(endpoint, data);
  }

  delete(endpoint) {
    return this.client.delete(endpoint);
  }
}

export default new ApiService();
